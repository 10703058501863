import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import FeaturePost from '../../../components/feature-post/feature-post';
import { BannerWrapper, BannerInner, FeaturePosts, Title } from './style';
import Customtagy from '../../sidebar/customtagy';

type BannerProps = {};

const Banner: React.FunctionComponent<BannerProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 5
        filter: { frontmatter: { tags: { eq: "featured" } } }
      ) {
        totalCount
        edges {
          node {
            excerpt(pruneLength: 300)
            fields {
              slug
            }
            frontmatter {
              date(formatString: "DD [<span>] MMM [</span>]")
              title
              description
              tags
              cover {
                childImageSharp {
                  fluid(maxWidth: 90, maxHeight: 90, quality: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }

      allStrapiArticles(filter: {public: {eq: true}}, sort: { fields: id, order: DESC }, limit: 6) {
        totalCount
        edges {
          node {
            excerpt
            slug
            createdAt
            public
            title
            tag
            cover {
              childImageSharp {
                fluid(maxWidth: 90, maxHeight: 90, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  `);

  const Posts = Data.allMarkdownRemark.edges;
  const CustomPosts = Data.allStrapiArticles.edges;

  return (
    <BannerWrapper>
      <BannerInner>
        <FeaturePosts>
          <Title>Nové články</Title>
          {CustomPosts.map(({ node }: any) => {
            const title = node.title || node.slug;
            // Random Placeholder Color
            const placeholderColors = [
              '#55efc4',
              '#81ecec',
              '#74b9ff',
              '#a29bfe',
              '#ffeaa7',
              '#fab1a0',
              '#e17055',
              '#0984e3',
              '#badc58',
              '#c7ecee',
            ];
            const setColor =
              placeholderColors[
                Math.floor(Math.random() * placeholderColors.length)
              ];

            let tags = [];
            tags.push(node.tag);

            
              return (
                <FeaturePost
                  key={node.slug}
                  title={title}
                  image={
                    node.cover == null ? null : node.cover.childImageSharp.fluid
                  }
                  url={node.slug}
                  tags={tags}
                  placeholderBG={setColor}
                />
              );
            
          })}
        </FeaturePosts>

        <FeaturePosts>
          <Title>Rubriky</Title>
          <Customtagy />
        </FeaturePosts>
      </BannerInner>
    </BannerWrapper>
  );
};

export default Banner;
