import * as React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Button from '../../../components/button/button';
import PostCardModern from '../../../components/post-card-modern/post-card-modern';
import BlogPostsWrapper, { PostRow, PostGrid, SeeMore } from './style';
const moment = require('moment');
moment.locale('cs');

type PostsProps = {};

const Posts: React.FunctionComponent<PostsProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 6
      ) {
        totalCount
        edges {
          node {
            excerpt(pruneLength: 200)
            fields {
              slug
            }
            frontmatter {
              date(formatString: "DD [<span>] MMM [</span>]")
              title
              description
              tags
              cover {
                childImageSharp {
                  fluid(maxWidth: 570, maxHeight: 370, quality: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }

      allStrapiArticles(filter: {public: {eq: true}}, sort: { fields: id, order: DESC }, limit: 6)  {
        totalCount
        edges {
          node {
            excerpt
            slug
            createdAt
            title
            tag
            public
            cover {
              childImageSharp {
                fluid(maxWidth: 570, maxHeight: 370, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  `);

  const Posts = Data.allMarkdownRemark.edges;
  const CustomPosts = Data.allStrapiArticles.edges;

  //console.log(CustomPosts);

  return (
    <BlogPostsWrapper>
      <PostRow>
        {CustomPosts.map(({ node }: any) => {
          const title = node.title || node.slug;
          // Random Placeholder Color
          const placeholderColors = [
            '#55efc4',
            '#81ecec',
            '#74b9ff',
            '#a29bfe',
            '#ffeaa7',
            '#fab1a0',
            '#e17055',
            '#0984e3',
            '#badc58',
            '#c7ecee',
          ];
          const setColor =
            placeholderColors[
              Math.floor(Math.random() * placeholderColors.length)
            ];

          let tags = [];
          tags.push(node.tag);
          let date = moment(node.createdAt).format('LL');

          
            return (
              <PostGrid key={node.slug}>
                <PostCardModern
                  key={node.slug}
                  title={title}
                  image={
                    node.cover == null ? null : node.cover.childImageSharp.fluid
                  }
                  url={node.slug}
                  description={node.excerpt}
                  // date={date}
                  tags={tags}
                  placeholderBG={setColor}
                />
              </PostGrid>
            );
         
        })}
      </PostRow>
      <SeeMore>
        <Link to="/stranka/1">
          <Button title="Více článků" type="submit" />
        </Link>
      </SeeMore>
    </BlogPostsWrapper>
  );
};

export default Posts;

/*
import * as React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Button from '../../../components/button/button';
import PostCardModern from '../../../components/post-card-modern/post-card-modern';
import BlogPostsWrapper, { PostRow, PostGrid, SeeMore } from './style';

type PostsProps = {};

const Posts: React.FunctionComponent<PostsProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 6
      ) {
        totalCount
        edges {
          node {
            excerpt(pruneLength: 200)
            fields {
              slug
            }
            frontmatter {
              date(formatString: "DD [<span>] MMM [</span>]")
              title
              description
              tags
              cover {
                childImageSharp {
                  fluid(maxWidth: 570, maxHeight: 370, quality: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }

      allStrapiArticles(sort: {fields: id, order: ASC}, limit: 6) {
        totalCount
        edges {
          node {
            excerpt
            slug
            createdAt
            title
            tag
            cover {
              childImageSharp {
                fluid(maxWidth: 570, maxHeight: 370, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }


    }
  `);

  const Posts = Data.allMarkdownRemark.edges;
  const CustomPosts = Data.allStrapiArticles.edges

 // console.log(CustomPosts);

  return (
    <BlogPostsWrapper>
      <PostRow>
        {Posts.map(({ node }: any) => {
          const title = node.frontmatter.title || node.fields.slug;
          // Random Placeholder Color
          const placeholderColors = [
            '#55efc4',
            '#81ecec',
            '#74b9ff',
            '#a29bfe',
            '#ffeaa7',
            '#fab1a0',
            '#e17055',
            '#0984e3',
            '#badc58',
            '#c7ecee',
          ];
          const setColor =
            placeholderColors[
              Math.floor(Math.random() * placeholderColors.length)
            ];

          return (
            <PostGrid key={node.fields.slug}>
              <PostCardModern
                key={node.fields.slug}
                title={title}
                image={
                  node.frontmatter.cover == null
                    ? null
                    : node.frontmatter.cover.childImageSharp.fluid
                }
                url={node.fields.slug}
                description={node.excerpt}
                date={node.frontmatter.date}
                tags={node.frontmatter.tags}
                placeholderBG={setColor}
              />
            </PostGrid>
          );
        })}
      </PostRow>
      <SeeMore>
        <Link to="page/1">
          <Button title="See more" type="submit" />
        </Link>
      </SeeMore>
    </BlogPostsWrapper>
  );
};

export default Posts;

*/
